<template>
  <div class="main-body">
    <div class="card-item">
      <div class="order-attention">
        <p>网站参数设置</p>
      </div>
      <a-divider />
      <div class="action-body">
        <a-row type="flex" :gutter="[16, 16]">
          <a-col :span="3" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4">
            <div class="action-item">
              <div class="action-item-btn" @click="goTo('/client/native/config?appid=')">
                <SettingOutlined class="action-icon" />
                <span>
                  <div class="title">站点配置</div>
                  <div class="desc">设置参数</div>
                </span>
              </div>
            </div>
          </a-col>
          <a-col :span="3" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4">
            <div class="action-item">
              <div class="action-item-btn" @click="goTo('/client/native/seo?appid=')">
                <SecurityScanOutlined class="action-icon" />
                <span>
                  <div class="title">SEO优化</div>
                  <div class="desc">利于搜索引擎抓取</div>
                </span>
              </div>
            </div>
          </a-col>
          <a-col :span="3" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4">
            <div class="action-item">
              <div class="action-item-btn" @click="goTo('/client/native/links?appid=')">
                <LinkOutlined class="action-icon" />
                <span>
                  <div class="title">友情链接</div>
                  <div class="desc">设置参数</div>
                </span>
              </div>
            </div>
          </a-col>
          <a-col :span="3" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4">
            <div class="action-item">
              <div class="action-item-btn" @click="goTo('/client/native/copyright?appid=')">
                <FormOutlined class="action-icon" />
                <span>
                  <div class="title">版权及备案信息</div>
                  <div class="desc">版权信息、ICP备案</div>
                </span>
              </div>
            </div>
          </a-col>
          <!-- <a-col :span="3" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4">
            <div class="action-item">
              <div class="action-item-btn" @click="goTo('/client/native/web?appid=')">
                <ChromeOutlined class="action-icon" />
                <span>
                  <div class="title">站点装修</div>
                  <div class="desc">自定义站点页面</div>
                </span>
              </div>
            </div>
          </a-col>
          <a-col :span="3" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4">
            <div class="action-item">
              <div class="action-item-btn" @click="goTo('/client/native/developer?appid=')">
                <Html5Outlined class="action-icon" />
                <span>
                  <div class="title">开发者文档</div>
                  <div class="desc">开发文档说明</div>
                </span>
              </div>
            </div>
          </a-col> -->
        </a-row>
      </div>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>网站发布流程</p>
      </div>
      <a-divider />
      <a-steps :current="5" size="small">
        <a-step title="域名注册" />
        <a-step title="域名备案" />
        <a-step title="上传代码" />
        <a-step title="绑定域名" />
        <a-step title="发布网站" />
      </a-steps>
      <div class="weixin-url">
        <span>域名注册（阿里云）：</span>
        <a href="https://wanwang.aliyun.com" target="_blank">https://wanwang.aliyun.com</a>
      </div>
      <div class="weixin-url">
        <span>域名注册（华为云）：</span>
        <a href="https://www.huaweicloud.com/product/domain.html" target="_blank">https://www.huaweicloud.com/product/domain.html</a>
      </div>
      <div class="weixin-url">
        <span>域名注册（腾讯云）：</span>
        <a href="https://dnspod.cloud.tencent.com" target="_blank">https://dnspod.cloud.tencent.com</a>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import {
  SettingOutlined,
  SecurityScanOutlined,
  LinkOutlined,
  FormOutlined,
  // Html5Outlined,
  // ChromeOutlined,
} from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: {
    SettingOutlined,
    SecurityScanOutlined,
    LinkOutlined,
    FormOutlined,
    // Html5Outlined,
    // ChromeOutlined,
  },
  setup() {
    const route = useRoute();
    const appid = route.query.appid;
    const router = useRouter();
    const goTo = (url) => {
      router.push(url + appid);
    };
    return {
      goTo,
    };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;

  .action-item {
    .action-item-btn {
      display: flex;
      background-color: #f0f2f5;
      margin: 20px;
      padding: 10px;
      border-radius: 3px;
      cursor: pointer;

      .action-icon {
        font-size: 36px;
        margin: 6px 10px;
        color: #52c41a;
      }

      .title {
        font-size: 14px;
      }

      .desc {
        color: #999999;
      }
    }
  }

  .weixin-url {
    margin: 40px 0;
  }
}
</style>